.profil-container {
	display: flex;
	flex-wrap: wrap;
	padding: 70px 0 130px 0;
	width: 65%;
	text-align: left;
}

.profil-container h3 {
	font-size: 16px;
	margin-bottom: 20px;
	width: 100%;
}

.profil-container h3:nth-of-type(2), .profil-container h3:nth-of-type(3) {
	margin-top: 40px;
}

.profil-container p {
	font-size: 14px;
	margin-bottom: 10px;
}

.clanovi {
	display: flex;
}

.clan-container {
	height: 200px;
}

.clan-container:nth-child(1) {
	margin-right: 20vw;
}

.clan-container img {
	height: 100%;
}

.clan-container p {
	margin-top: 10px;
	font-weight: bold;
}

@media only screen and (max-width: 750px) {
	.profil-container {
		width: 90%;
		padding: 40px 0 130px 30px;
	}
	.profil-container h3 {
		font-size: 15px;
	}
	.profil-container h3:nth-of-type(2), .profil-container h3:nth-of-type(3) {
		margin-top: 35px;
	}
	.profil-container p {
		font-size: 13px;
	}
}

@media only screen and (max-width: 450px) {
	.clanovi {
		flex-wrap: wrap;
	}
	.clan-container:nth-child(1) {
		margin-right: 0;
		width: 100%;
		margin-bottom: 60px;
	}
	.profil-container h3 {
		font-size: 14px;
	}
	.profil-container h3:nth-of-type(2), .profil-container h3:nth-of-type(3) {
		margin-top: 20px;
	}
	.profil-container p {
		font-size: 12px;
	}
}