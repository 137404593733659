.header {
	width: 20%;
	padding: 40px 0 0 30px;
	/* overflow-x: hidden; */
	/* overflow-y: auto; */
}
.mini-logo {
	max-height: 100px;
	display: block;
	margin-bottom: 50px;
}
.main-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.main-nav a {
	text-decoration: none;
	color: rgb(0, 0, 0);
	display: inline-block;
	margin-bottom: 15px;
}

.menu-button {
	display: none;
}

.active-nav-link {
	text-transform: uppercase;
}

@media only screen and (max-width: 750px) {
	.header {
		position: relative;
		width: 100%;
		justify-content: space-between;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.mini-logo {
		margin-bottom: 0;
	}

	.main-nav {
		display: flex;
		position: absolute;
		top: 100%;
		left: 100%;
		width: 100%;
		height: 40vh;
		background: rgb(255, 255, 255);
		padding-top: 40px;
		z-index: 5;
		border-bottom: 1px solid rgb(220, 220, 220);
		transition: left 600ms ease-in-out;
	}

	.show-nav {
		left: 0;
	}

	.main-nav a {
		margin-left: 30px;
		margin-bottom: 30px;
	}

	.menu-button {
		display: block;
		margin-right: 10%;
		width: 30px;
		height: 30px;
		background-size: 30px;
		cursor: pointer;
	}

	.menu-button > img {
		width: 30px;
		display: inline-block;
	}

	.closed-menu-button {
		background: url("../img/icons/menu.png") no-repeat;
	}

	.open-menu-button {
		background: url("../img/icons/close.png") no-repeat;
	}
}