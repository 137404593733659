.home-page-container {
	position: relative;
	width: 100%;
}

.main-link {
	display: inline-block;
	width: 70%;
	margin-top: 10vh;
}

.main-link > img {
	width: 100%;
}

@media only screen and (max-width: 900px) {
	.main-link {
		width: 90%;
	}
}