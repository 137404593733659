.projects-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 75%;
	padding-top: 70px;
	padding-bottom: 150px;
}

.project-link {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
}

.project-element {
	width: 32%;
	padding-top: calc(55.6 * 0.32%);
	margin-right: 2%;
	margin-bottom: 2%;
	position: relative;
	overflow: hidden;
	border: 1px solid rgb(200, 200, 200);
}

.project-element:nth-child(3n+3) {
	margin-right: 0;
}

.project-element .image-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	color: rgb(50, 50, 50);
	text-align: left;
	font-size: 14px;
	padding: 15px 0 0 15px;
	background: rgba(255, 255, 255, 0.9);
	transition: opacity 300ms ease-in-out;
	z-index: 2;
}

.project-element .image-overlay:hover {
	opacity: 1;
	cursor: pointer;
	
}

.project-element img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(255, 255, 255);
	z-index: 4;
}

.loader-container > .loader {
	z-index: 3;
}

@media only screen and (max-width: 1100px) {
	.projects-container > .project-element {
		width: 49%;
		padding-top: calc(55.6 * 0.49%);
		margin-right: 0;
		margin-right: 2%;
	}
	.project-element:nth-child(even) {
		margin-right: 0;
	}
}

@media only screen and (max-width: 750px) {
	.projects-container {
		width: 90%;
		padding: 20px 0 0 30px;
	}
	.projects-container > .project-element {
		width: 100%;
		padding-top: 55.6%;
		margin-right: 0;
	}
	.project-element .image-overlay {
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0;
		right: auto;
		opacity: 1;
		width: 100%;
		padding: 15px 0 15px 15px;
		font-size: 13px;
	}
}

@media only screen and (max-width: 450px) {
	.project-element .image-overlay {
		font-size: 12px;
		padding: 10px 0 10px 10px;
	}
}