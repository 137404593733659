.App {
  text-align: center;
  display: flex;
  overflow: hidden;
}

@media only screen and (max-width: 750px) {
	.App {
		flex-wrap: wrap;
	  }
}