h2 {
	margin-top: 50px;
	margin-right: 50px;
}

@media only screen and (max-width: 750px) {
	h2 {
		margin-top: 50px;
		margin-right: 0;
		margin-left: 30px;
	}
}