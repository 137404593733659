.kontakt-container {
	padding: 80px 0 130px 0;
	width: 70%;
	text-align: left;
}

.kontakt-container h3 {
	font-size: 16px;
	margin-bottom: 20px;
	width: 100%;
}

.kontakt-container p {
	font-size: 15px;
	margin-bottom: 5px;
}

.kontakt-container p:nth-of-type(3), .kontakt-container p:nth-of-type(4), .kontakt-container p:nth-of-type(5) {
	margin-bottom: 20px;
}

@media only screen and (max-width: 750px) {
	.kontakt-container {
		width: 70%;
		padding: 40px 0 130px 30px;
	}

	.kontakt-container h3 {
		font-size: 15px;
	}
	
	.kontakt-container p {
		font-size: 14px;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 450px) {
	.kontakt-container h3 {
		font-size: 14px;
	}
	
	.kontakt-container p {
		font-size: 13px;
	}
}