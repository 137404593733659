.project-container {
	width: 70%;
	padding-top: 70px;
	padding-bottom: 150px;
}

.slider-container {
	width: 90%;
	height: 480px;
	position: relative;
	overflow: hidden;
}

.slider-container .image-container {
	position: absolute;
	width: 100%;
	height: 480px;
	transition: left 600ms ease-in-out, opacity 600ms;
}

.image-container img {
	position: absolute;
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.dots-container {
	text-align: left;
	margin-top: 15px;
}

.dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: rgb(150, 150, 150);
	display: inline-block;
	margin-right: 15px;
	cursor: pointer;
}

.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgb(255, 255, 255);
}

.loader {
	display: inline-block;
	width: 30%;
	max-width: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.project-description {
	text-align: left;
	margin-top: 15px;
}

.project-description p {
	font-size: 14px;
	margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
	.project-container {
		padding-top: 50px;
	}
	.slider-container {
		height: 300px;
	}
	.slider-container .image-container {
		height: 300px;
	}
}

@media only screen and (max-width: 750px) {
	.project-container {
		padding: 20px 0 0 30px;
	}
	.project-container {
		width: 100%;
		padding-top: 70px;
		padding-bottom: 150px;
	}
}

@media only screen and (max-width: 600px) {
	.slider-container {
		height: 250px;
	}
	.slider-container .image-container {
		height: 250px;
	}
	.project-description p {
		font-size: 13px;
	}
}

@media only screen and (max-width: 450px) {
	.slider-container {
		height: 200px;
	}
	.slider-container .image-container {
		height: 200px;
	}
	.project-description p {
		font-size: 12px;
	}
}